<template>
  <div class="d-flex full-height full-width">
    <dynamic-data-table
      :can-delete="true"
      :can-edit="true"
      :table-headers="headers"
      :table-records="filteredArticles"
      :table-title="
        tableState === 'Elements'
          ? $t('modules.BillOfMaterial.groups.elements')
          : $t('modules.BillOfMaterial.groups.objects')
      "
      class="full-width"
      @deleteItem="setupDelete"
      @editItem="setupEdit"
    >
      <template #table-buttons>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              :color="tableState === 'Elements' ? 'primary' : ''"
              @click="tableState = 'Elements'"
              v-on="on"
            >
              mdi-cube-outline
            </v-icon>
          </template>
          <span>Elements</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              :color="tableState === 'Objects' ? 'primary' : ''"
              class="ml-2"
              @click="tableState = 'Objects'"
              v-on="on"
            >
              mdi-cube-unfolded
            </v-icon>
          </template>
          <span>Objects</span>
        </v-tooltip>
      </template>
      <template #table-actions>
        <v-btn
          class="button mr-2"
          color="primary"
          elevation="0"
          small
          @click="openArticleImport"
        >
          {{ $t('modules.BillOfMaterial.importItems') }}
          {{
            tableState === 'Elements'
              ? $t('modules.BillOfMaterial.groups.elements')
              : $t('modules.BillOfMaterial.groups.objects')
          }}
        </v-btn>
      </template>
    </dynamic-data-table>
    <delete-dialog
      :dialog="articleDeleteDialog"
      :title="
        $t('modules.BillOfMaterial.groups.notifications.confirmDelete', {
          item: articleDeleteItem.object_type,
        })
      "
      @closeDialog="closeArticleDialogs"
      @deleteAction="deleteArticle"
    />
    <v-dialog
      key="bom-article-import"
      v-model="articleImportDialog"
      max-width="800px"
      @click:outside="closeArticleDialogs"
    >
      <v-card>
        <v-card-title class="justify-center text-uppercase headline"
          >{{
            $t('modules.BillOfMaterial.groups.addToS', {
              type:
                tableState === 'Elements'
                  ? $t('modules.BillOfMaterial.groups.elements')
                  : $t('modules.BillOfMaterial.groups.objects'),
              supplier: dhmeBillOfMaterialFocusedGroup.name,
            })
          }}
        </v-card-title>
        <v-divider />
        <div class="px-10 py-5">
          <v-text-field
            v-model="importSearch"
            :disabled="articleImportItemsStatus === 'loading'"
            class="mb-2"
            clearable
            dense
            filled
            flat
            hide-details
            placeholder="Type here to search"
            prepend-inner-icon="mdi-magnify"
            rounded
          />
          <v-tabs
            v-if="!importSearch && tableState === 'Elements'"
            v-model="categoryTab"
            center-active
            grow
            height="46"
            style="width: auto"
          >
            <v-tab v-for="category in categories" :key="category">
              {{ category }}
            </v-tab>
          </v-tabs>
          <div
            v-if="!importSearch"
            class="d-flex px-4 align-center justify-center"
          >
            <v-subheader class="px-0">Select all</v-subheader>
            <v-spacer />
            <v-checkbox
              v-model="selectAllToggle"
              hide-details
              @click="toggleSelectAll"
            />
          </div>
          <v-list
            v-if="articleImportItemsStatus === 'success'"
            class="overflow-y-auto"
            dense
            style="height: 300px"
          >
            <v-list-item
              v-for="(item, index) in filteredImportArticles"
              :key="index"
              class="d-flex"
            >
              <div style="width: 150px">
                {{ item.type }}
              </div>
              <v-divider class="my-2 mx-5" vertical />
              <div>
                {{ item.extra }}
              </div>
              <v-spacer />
              <v-chip
                v-if="isArticleImportedInGroup(item)"
                color="primary"
                x-small
              >
                imported
              </v-chip>
              <v-checkbox v-else v-model="item.selected" hide-details />
            </v-list-item>
          </v-list>
          <div
            v-else
            class="d-flex align-center justify-center"
            style="height: 500px"
          >
            <ant-loading />
          </div>
        </div>
        <v-card-actions class="ant-border-top ant-dialog-actions-bg">
          <v-spacer />
          <v-btn color="error" small text @click="closeArticleDialogs">
            {{ $t('general.cancel') }}
          </v-btn>
          <v-btn color="primary" elevation="0" small @click="importArticles">
            {{ $t('general.import') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      key="bom-edit-article"
      v-model="articleEditDialog"
      max-width="500px"
      @click:outside="closeArticleDialogs"
    >
      <v-card>
        <v-card-title>
          {{
            articleItem.id
              ? $t('general.update')
              : $t('modules.BillOfMaterial.groups.addTo')
          }}
          {{ dhmeBillOfMaterialFocusedGroup.name }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="6">
                <v-text-field
                  v-if="tableState === 'Elements'"
                  v-model="articleItem.element_type"
                  label="Element type"
                />
                <v-text-field
                  v-else-if="tableState === 'Objects'"
                  v-model="articleItem.object_type"
                  label="Object type"
                />
                <v-text-field v-model="articleItem.name" label="Name" />
                <v-text-field
                  v-model="articleItem.description"
                  label="Description"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="closeArticleDialogs">
            {{ $t('general.cancel') }}
          </v-btn>
          <v-btn color="primary" @click="saveArticle">
            {{ $t('general.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import { DHME_BILL_OF_MATERIAL } from '@/modules/modules';
import AntLoading from '@/components/AntLoading.vue';
import { executeCustomModuleCall } from '@/services/api/module.api';

export default {
  name: 'DhmeBillOfMaterialGroups',
  components: { AntLoading, DeleteDialog, DynamicDataTable },
  data: () => {
    return {
      tableState: 'Elements',
      articleImportDialog: false,
      articleImportItems: [],
      articleEditDialog: false,
      articleDeleteDialog: false,
      articleDeleteItem: {},
      articleItem: {},
      importTab: 0,
      selectedElementTypes: {},
      selectedObjectTypes: {},
      importSearch: null,
      objectSearch: null,
      articleImportItemsStatus: '',
      categoryTab: 0,
      selectAllToggle: false,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'dhmeBillOfMaterialFocusedGroup',
      'dhmeBillOfMaterialResourceGroups',
      'dhmeBillOfMaterialResourceGroupObjects',
      'dhmeBillOfMaterialResourceGroupElements',
    ]),
    headers() {
      return [
        {
          text:
            this.tableState === 'Elements'
              ? this.$t('modules.BillOfMaterial.groups.table.elementType')
              : this.$t('modules.BillOfMaterial.groups.table.objectType'),
          value:
            this.tableState === 'Elements' ? 'element_type' : 'object_type',
        },
        {
          text: this.$t('modules.BillOfMaterial.groups.table.name'),
          value: 'name',
        },
        {
          text: this.$t('modules.BillOfMaterial.groups.table.desc'),
          value: 'description',
        },
        {
          text: this.$t('modules.BillOfMaterial.groups.table.actions'),
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ];
    },
    filteredArticles() {
      switch (this.tableState) {
        case 'Elements':
          return this.dhmeBillOfMaterialResourceGroupElements.filter(
            (group) =>
              group.group_code ===
              this.dhmeBillOfMaterialFocusedGroup.group_code
          );
        case 'Objects':
          return this.dhmeBillOfMaterialResourceGroupObjects.filter(
            (group) =>
              group.group_code ===
              this.dhmeBillOfMaterialFocusedGroup.group_code
          );
        default:
          return [];
      }
    },
    categories() {
      return [...new Set(this.articleImportItems.map((item) => item.extra))];
    },
    filteredImportArticles() {
      if (this.importSearch) {
        return [...this.articleImportItems]
          .filter(
            (item) =>
              item.type &&
              item.type.toLowerCase().includes(this.importSearch.toLowerCase())
          )
          .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
      } else {
        return [...this.articleImportItems]
          .filter(
            (item) =>
              item.type && item.extra === this.categories[this.categoryTab]
          )
          .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
      }
    },
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_BILL_OF_MATERIAL
      ).id;
    },
  },
  watch: {
    categoryTab() {
      this.selectAllToggle = false;
      this.articleImportItems.map((item) => {
        item.selected = false;
        return item;
      });
    },
  },
  methods: {
    toggleSelectAll() {
      this.articleImportItems.map((item) => {
        if (item.extra === this.categories[this.categoryTab]) {
          item.selected = this.selectAllToggle;
        }
        return item;
      });
    },
    openArticleImport() {
      this.getUniqueArticleTypes();
      this.articleImportDialog = true;
    },
    isArticleImportedInGroup(item) {
      switch (this.tableState) {
        case 'Elements':
          return this.dhmeBillOfMaterialResourceGroupElements.find(
            (mapping) =>
              mapping.element_type === item.type &&
              mapping.group_code ===
                this.dhmeBillOfMaterialFocusedGroup.group_code
          );
        case 'Objects':
          return this.dhmeBillOfMaterialResourceGroupObjects.find(
            (mapping) =>
              mapping.object_type === item.type &&
              mapping.group_code ===
                this.dhmeBillOfMaterialFocusedGroup.group_code
          );
        default:
          return false;
      }
    },
    getUniqueArticleTypes() {
      this.articleImportItems = [];
      this.articleImportItemsStatus = 'loading';
      switch (this.tableState) {
        case 'Elements':
          executeCustomModuleCall(
            this.project.id,
            this.moduleId,
            'getUniqueElements',
            {
              project: {
                id: this.project.id,
              },
            }
          ).then((response) => {
            this.articleImportItems = response.map((item) => {
              item.selected = false;
              return item;
            });
            this.articleImportItemsStatus = 'success';
          });
          break;
        case 'Objects':
          executeCustomModuleCall(
            this.project.id,
            this.moduleId,
            'getUniqueObjects',
            {
              project: {
                id: this.project.id,
              },
              group: this.dhmeBillOfMaterialFocusedGroup.group_code,
            }
          ).then((response) => {
            this.articleImportItems = response.map((item) => {
              item.selected = false;
              return item;
            });
            this.articleImportItemsStatus = 'success';
          });
          break;
        default:
          this.articleImportItems = [];
          break;
      }
    },

    setupEdit(item) {
      this.articleItem = Object.assign({}, item);
      this.articleEditDialog = true;
    },
    setupDelete(item) {
      this.articleDeleteItem = Object.assign({}, item);
      this.articleDeleteDialog = true;
    },
    closeArticleDialogs() {
      this.articleImportDialog = false;
      this.articleEditDialog = false;
      this.articleDeleteDialog = false;
      this.articleItem = Object.assign({}, {});
      this.articleDeleteItem = Object.assign({}, {});
    },
    saveArticle() {
      if (this.articleItem.id !== undefined) {
        this.$store
          .dispatch('updateResourceGroupObjectRelation', {
            recordId: this.articleItem.id,
            record: this.articleItem,
            tableState: this.tableState,
          })
          .then(() => {
            this.closeArticleDialogs();
          });
      } else {
        this.articleItem.group_code =
          this.dhmeBillOfMaterialFocusedGroup.group_code;
        this.$store
          .dispatch('createResourceGroupObjectRelation', {
            record: this.articleItem,
            tableState: this.tableState,
          })
          .then(() => {
            this.closeArticleDialogs();
          });
      }
    },
    importArticles() {
      let filteredArticles = this.articleImportItems.filter(
        (item) => !this.isArticleImportedInGroup(item) && item.selected
      );

      let articlesToImport = [];
      switch (this.tableState) {
        case 'Elements':
          articlesToImport = filteredArticles.map((item) => {
            return {
              id: null,
              element_type: item.type,
              name: null,
              description: item.extra,
              group_code: this.dhmeBillOfMaterialFocusedGroup.group_code,
            };
          });
          break;
        case 'Objects':
          articlesToImport = filteredArticles.map((item) => {
            return {
              id: null,
              object_type: item.type,
              name: null,
              description: item.extra,
              group_code: this.dhmeBillOfMaterialFocusedGroup.group_code,
            };
          });
          break;
      }

      this.$store
        .dispatch('importMultipleGroupObjectRelations', {
          articles: articlesToImport,
          tableState: this.tableState,
        })
        .then(() => {
          this.closeArticleDialogs();
        });
    },
    deleteArticle() {
      this.$store
        .dispatch('removeResourceGroupObjectRelation', {
          recordId: this.articleDeleteItem.id,
          tableState: this.tableState,
        })
        .then(() => {
          this.closeArticleDialogs();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.supplier-header {
  font-size: 18px;
  font-weight: 500;
}

.supplier-sub-header {
  font-style: italic;
}
</style>
